@import "styles/variables.scss";

div.material-basic-modal.full-opacity {
  backdrop-filter: blur(5px);
}

div.basic-modal {
  div.box-modal {
    overflow-y: auto; /* Activar barra de desplazamiento vertical */
    overflow-x: hidden; /* Desactivar barra de desplazamiento horizontal */

    /* Estilos para la barra de desplazamiento */
    &::-webkit-scrollbar {
      width: 5px; /* Ancho de la barra de desplazamiento */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #a543e1; /* Color del pulgar de la barra de desplazamiento */
      border-radius: 4px; /* Borde redondeado del pulgar */
    }

    div.box-modal-footer {
      hr.divider {
        margin: 1.5rem 0;
      }
      position: absolute;
      width: 100%;
      bottom: 4rem;
      left: 0px;
    }
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    background-color: white;
    padding: 2.5rem;
    padding-bottom: 1.5rem;
    border-radius: 10px;

    div.button-modal {
      text-align: center;
      
    }
    .children {
      justify-content: center;
    }
  }
}
