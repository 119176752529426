@import "styles/variables.scss";

$primary-color: #065b72;
$secondary-color: #95a5a6;
$accent-color: #3498db;
$light-background: #f5f6f7;
$white: #fff;
$shadow-color: rgba(0, 0, 0, 0.2);

$color1: #e88e72; 
$color2: #8b3bd5;

.contact-container {
  background: $background-color-gradiant;
  background-size: 400% 400%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: backgroundAnimation 20s ease-in-out infinite;
  flex-direction: column; 
}

@keyframes backgroundAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes titleAnimation {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.title-container {
  justify-content: center;
  text-align: center;
  .title {
    animation: titleAnimation 1.5s ease-out;
    max-width: 60%;
  }
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  animation: cardsAnimation 2s ease-out;
  max-width: 90%;
}

@keyframes cardsAnimation {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.card-contact {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.9),
    rgba(255, 255, 255, 0.9)
  );
  border-radius: 18px;
  box-shadow: 0 8px 18px $shadow-color;
  width: 350px;
  height: 420px;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  overflow: hidden;
  cursor: pointer;
  backdrop-filter: blur(10px); 

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 25px $shadow-color;
  }

  .card-header {
    text-align: center;
    padding-top: 30px;
    position: relative;
  }

  .profile-img {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    object-fit: cover;
    border: 4px solid $white;
    box-shadow: 0 0 15px $secondary-color-1;
    transition: transform 0.3s ease;
  }

  .card-header:hover .profile-img {
    transform: scale(1.1);
  }

  .card-body {
    padding: 25px;
    text-align: left;
    background-color: $white;
    border-top: 2px solid $secondary-color-1;
    height: 100vh;

    h2 {
      font-size: 1.8rem;
      color: $dark-color-1;
      margin-bottom: 12px;
      font-weight: 700;
      letter-spacing: 0.5px;
    }

    .role {
      font-size: 1.2rem;
      color: $secondary-color-3;
      margin-bottom: 20px;
      font-weight: 400;
    }

    .contact-info {
      color: $dark-color-2;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      
      // Estilos para los íconos de FontAwesome (usando sus clases específicas)
      .fa-envelope,
      .fa-whatsapp {
        font-size: 1.5rem; // Tamaño de los íconos
        color: $tertiary-color-2; // Color de los íconos
        margin-right: 1rem; // Espacio entre el ícono y el texto
      }

      a {
        color: $tertiary-color-2;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: darken($tertiary-color-2, 10%);
        }
      }
    }
  }
}
