@import "styles/variables.scss";

div.account-status-filter-modal {
  width: 35rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $background-color-1;
  padding: 2.5rem;
  border-radius: 10px;

  .filter-modal-content {
    &-date-from {
      margin-right: 1rem;
    }
  }

  .button-confirmation-modal {
    margin-top: 3rem;
  }
}
