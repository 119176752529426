@import "styles/variables.scss";

div.cart-page {
  div.cart-content {
    padding: 0 3rem;
    margin-top: 1.5rem;

    .cart-content-header {
      .cart-content-header-total-to-collect {
        margin-right: 3rem;
        max-width: 16rem;
        height: 6.5rem;
        border: 1px solid $secondary-color-1;
        text-align: center;
        box-shadow: 0px 1px 4px 0px $secondary-color-1;
        border-radius: 10px;
        padding: 20px;

        .cart-content-header-total-to-collect-title {
          margin-bottom: 1rem;
        }
      }
      .cart-content-header-buttons {
        display: flex;
        justify-content: right;

        .cart-content-button {
          margin-left: 2rem;
        }
      }
    }

    .cart-content-products {
      margin-top: 2rem;
    }
  }
}
