@import "styles/variables.scss";

div.tooltip {

}

div.MuiTooltip-tooltip {
  background-color: $secondary-color-3;

  p {
    color: $background-color-1;
  }

  span {
    color: $secondary-color-3;
  }
}