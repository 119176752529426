@import "styles/variables.scss";

div.select-input {
  max-height: 6rem;

  .select-input-container {
    margin-top: 0.75rem;
    .helper-text {
      margin-top: 5px;
      margin-bottom: 5px;
      .success {
        color: green;
      }

      .warning {
        color: orange;
      }

      .error {
        color: red;
      }
    }
  }

  // Editing Material components
  .MuiInputBase-formControl {
    border-radius: 8px;
    height: 3.5rem;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $primary-color-1 !important;
  }
  .css-es1mxv-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: $primary-color-1 !important;
  }
}
