@import "styles/variables.scss";

div.date-input {
  div.button-level {
    width: auto;
  }
  max-height: 6rem;

  input {
    height: 1.5rem;
    padding: 1rem;
  }

  div.date-picker-input {
    width: 100%;
  }

  // Editing Material components
  .MuiInputBase-formControl {
    margin-top: 0.75rem;
    border-radius: 8px;
  }
  .MuiInputBase-formControl:hover {
    svg path {
      stroke: $primary-color-2 !important;
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $primary-color-1 !important;
  }
  .css-ndxio3-MuiInputBase-root-MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: $primary-color-2 !important;
  }

  // styles of date picker input by hours
  .react-datepicker {
    div.react-datepicker__time-container {
      div.react-datepicker__header.react-datepicker__header--time {
        display: none;
      }

      div.react-datepicker__time {
        padding: 1rem 0;

        .react-datepicker__time-list {
          display: flex;
          flex-direction: column;
          height: 270px;
          align-items: center;

          li {
            padding: 0.5rem !important;
            font-family: "Lato";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 13px;
            display: flex;
            align-items: center;
            text-align: center;
            color: $secondary-color-2;
          }

          li.react-datepicker__time-list-item {
            &:hover {
              color: $primary-color-2;
              background-color: white;
            }
          }

          li.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
            background-color: $primary-color-1 !important;
            color: white !important;
            border-radius: 15px;
          }
        }
      }

      @extend .scrollbar-custom;
    }
  }

  // styles of date picker input by periods and days
  div.react-datepicker-popper {
    div.react-datepicker__header {
      background-color: white;
      border: none;

      .react-datepicker__day-names {
        display: flex;
        justify-content: space-between;
        padding: 0.25rem 0.5rem;
        margin: 0 0;
      }
    }

    div.date-picker-popper-header-period {
      width: 100%;
      padding: 0.25rem;
      background-color: white;
    }
    div.date-picker-popper-header-day {
      width: 100%;
      padding: 0.25rem;
      background-color: white;
      p {
        min-width: 7.2rem;
      }
    }

    div.react-datepicker {
      .react-datepicker__month {
        .react-datepicker__week {
          display: flex;
          justify-content: space-between;
        }

        .react-datepicker__month-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        .react-datepicker__month-text--disabled {
          pointer-events: none !important;
        }
        .react-datepicker__month-wrapper div {
          padding: 0.5rem 0.25rem;
          border-radius: 10px;

          &:hover {
            color: $primary-color-1;
            background-color: white;
          }
        }

        .react-datepicker__day {
          border-radius: 100%;
          padding: 2px;
        }

        // Selected
        .react-datepicker__month-text--selected {
          background-color: $primary-color-1 !important;
          color: white !important;
        }
        .react-datepicker__day--selected {
          background-color: $primary-color-1 !important;
          color: white !important;
        }
      }
    }
  }

  // styles of date picker input time (HH:mm)
  div.custom-datepicker-time {
    background-color: red !important;
    width: 8rem;
    height: 9rem;

    .react-datepicker {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 8px;
    }
    .react-datepicker--time-only {
      width: 100%;
    }
    .react-datepicker__time-box {
      width: 100%;
    }
    .react-datepicker__time {
      width: 100%;
      height: 9rem;
    }
    .react-datepicker__time-list {
      width: 100%;
      height: 10rem !important;
    }
    .react-datepicker__time-container {
      width: 100%;
      border-radius: 8px;
      border: none;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
    }
  }
  .react-datepicker__triangle {
    display: none;
  }
  .calendar-only-hours {
    .react-datepicker__input-container {
      padding: 0;
      input {
        font-size: 32px;
        margin: 8px 0;
        border: none;
        padding: 0;
      }
      input:focus {
        outline: none;
      }

      border-bottom: 1px solid $primary-color-1;
    }
  }
  .calendar-only-hours.disabled {
    .react-datepicker__input-container {
      border-bottom: 1px solid $secondary-color-3;

      input {
        background: white;
      }
    }
  }
}

div.react-datepicker-wrapper {
  width: 100%;
}
