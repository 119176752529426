@import "styles/variables.scss";

div.alert {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 2;

  div.alert-material {
    margin: 1rem;
    width: 30rem;
    height: 2.5rem;
  }

  .MuiAlert-icon {
    display: flex;
    align-items: center;
  }

  .MuiAlert-message {
    width: 100%;
  }

  div.alert-type-success {
    background-color: $green-color-2;
    p {
      color: $background-color-2;
    }
  }
  div.alert-type-default {
    background-color: $secondary-color-3;
    p {
      color: $background-color-2;
    }
  }
  div.alert-type-error {
    background-color: $red-color-2;
    p {
      color: $background-color-2;
    }
  }
}
