@import "styles/variables.scss";

.supplier-dashboard {
  div.supplier-dashboard-content {
    padding: 0 3rem;
    margin-top: 1.5rem;
  }

  .supplier-dashboard-spinner-loading {
    margin-top: 50vh;
  }

  .supplier-dashboard-header-button {
    width: 12rem;
  }

  div.supplier-dashboard-content-status {
    div.button-status .button-status-typography {
      border-radius: 16px;
      box-shadow: 4px 4px 8px rgba(20, 34, 114, 0.32);
    }
  }

  div.supplier-dashboard-header {
    &-filter {
      margin-right: 2rem;
      min-width: 150px;

      button {
        border-radius: 6px;
        max-height: 40px;
      }
    }
  }
}
