@import "styles/variables.scss";

div.confirmation-modal-container {
  // width: 22rem;
  margin-bottom: 2rem;

  .confirmation-modal-title {
    margin-bottom: 1rem;
  }
  .confirmation-modal-description {
    margin-bottom: 1rem;
  }
}
