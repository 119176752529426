
.App-header {
  background-color: #ffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
