@import "styles/variables.scss";
.card-tag-xs {
  button.card-tag-button {
    background: none;
    border: none;
    height: 1px;
    width: 100%;
  }

  background-color: $background-color-1;
  border: 1px solid $primary-color-1 !important;
  height: 10rem;
  box-shadow: 6px 0px 6px -2px rgba(0, 0, 0, 0.1);
  width: 20rem;
  border-radius: 20px;

  .card {
    padding-top: 15px;
    height: 10rem;

    img {
      width: 105px;
      height: 105px;
      top: 30px;
      left: 86px;
      border-radius: 26px;
    }
    .card-tag-content {
      padding-top: 16px;
      p {
        padding-bottom: 0.5rem;
      }
      .card-tag-name {
        text-align: initial;
        padding-left: 1.5rem;
      }
      .card-tag-founder {
        justify-content: center;
        p {
          color: #a5a5a5;
        }
      }
      .card-tag-garage-online {
        justify-content: center;
        p {
          color: #8b3bd5;
        }
      }
 
      .add-button {
        svg {
          height: 2.5rem;
          width: 2.5rem;
          color: $primary-color-1;
        }
      }
      .add-button:hover .MuiSvgIcon-root {
        transform: scale(1.3); /* Agranda el ícono al 130% */
        transition: transform 0.3s ease-in-out; /* Hace que el cambio sea suave */
      }
      .card-tag-products {
        padding-left: 1.5rem;
      }
    }
  }
}
