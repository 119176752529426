@import "styles/variables.scss";

div.desktop-menu {
  background-color: $background-color;
  a img {
    width: 1rem;
    height: 1rem;
  }

  div.menu-item-sign-out {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

div.collapsed-menu {
  transition: transform 0.3s ease; // Transición suave para el contenedor
  
  &:hover {
    transform: scale(1.03); // Aumenta el tamaño del contenedor principal al hacer hover
    transition: transform 1s;
  }
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 95%;
  margin-top: 1.5rem;
  margin-left: 1.5rem;
  width: $desktop-menu-width;
  box-shadow: 6px 0px 6px -2px rgba(0, 0, 0, 0.1);
  z-index: 2;
  background-color: white;
  border-radius: 1rem;
  border: 1px $background-color-3 solid;
  div.menu-logo {
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    img {
      width: 90px;
    }
  }

  div.menu-item {
    
    margin: 5px 0;
    justify-content: center;
    overflow: hidden;
  }
  div.menu-item.logout-menu-item {
    margin-top: auto;
    padding-bottom: 1rem;
  }
}

div.desktop-menu-drawer {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  width: $drawer-width;
  height: 100vh;
  background-color: $background-color-1;
  transition: top 0.3s ease;

  div.logo-container {
    justify-content: center;
    padding: 1rem;

    align-items: center;
    img {
      width: 120px;
    }

    div.close {
      margin-top: 2rem;
      button {
        svg {
          color: $primary-color-1;
        }
      }
    }
  }
  div.menu-item a {
    background-color: $background-color-1;
    color: $secondary-color-3;

    img {
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  div.menu-item-sign-out a {
    background-color: $background-color-1;
    position: absolute;
    bottom: 0;
    min-height: 3rem;
  }
}

div.desktop-menu-body {
  padding-left: 0.4rem;
  margin-left: calc($desktop-menu-width - 0.7rem);
}

div.desktop-menu-body.empty-state {
  padding: 0 !important;
  margin-left: calc($desktop-menu-width - 0.7rem);
}

@media only screen and (min-width: 1024px) and (max-width: 1518px) and (min-height: 600px) and (max-height: 832px) {
  div.collapsed-menu {
    div.menu-item {
      margin: 4px 0;
      justify-content: center;
      overflow: hidden;
      a img {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    div.menu-logo {
      img {
        width: 53px;
        height: 28px;
      }
    }
  }
}
