@import "styles/variables.scss";

div.open-checkout-modal {
  &-content {
    max-width: 40vh;
    &-title,
    &-info {
      margin-bottom: 2rem;
    }

    &-pin {
      margin-bottom: 3rem;
    }

    &-radios {
      margin-bottom: 3rem;
    }

    &-name {
      margin-bottom: $drawer-item-margin-bottom;

      &:last-child {
        margin-bottom: 3rem;
      }
    }
  }
}
