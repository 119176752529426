@import "styles/variables.scss";
div.basic-modal-conditions {
  .box-modal-conditions {
    height: 90%;
    width: 70%;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    background-color: white;
    border: 2px solid black;
    border-radius: 10px;

    div.box-modal-footer-conditions {
      hr.divider {
        margin: 1.5rem 0;
      }
      position: absolute;
      width: 100%;
      bottom: 0px;
      left: 0px;
    }

    .children-conditions {
      .logo-conditions {
        justify-content: center;
        height: 150px;
        background: #e5e8f7;
        align-items: center;
        border-radius: 10px 10px 0px 0px;
      }
      
      .content {
        max-height: 75vh;
        @extend .scrollbar-custom;
        padding-bottom: 2rem;
        padding-right: 6rem;
        padding-left: 6rem;

        .title-conditions {
          margin-top: 2rem;
          justify-content: center;
          color: $background-color;
          background: $background-color;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          p {
            font-size: 43px;
            font-weight: 500;
            text-align: center;
          }
        }

        .subtitle {
          margin-bottom: 1rem;
          margin-top: 1rem;
        }
        .body-data {
          padding-left: 1rem;
          padding-right: 2rem;
        }
      }
    }
    .button-accept {
      margin-top: 2rem;
      div {
        justify-content: center;
      }
      button {
        width: 432px;
      }
    }
  }
}
