@import "styles/variables.scss";

.desktop-session {
  .left-effect {
    img {
      opacity: 0.7;
      position: fixed;
      top: -200px;
      left: -250px;
      z-index: 1;
    }
  }
  .right-effect {
    img {
      opacity: 0.4;
      position: fixed;
      bottom: -400px;
      right: -300px;
      z-index: 1;
    }
  }
  .session-content {
    justify-content: center;
    padding-top: 4rem;
    z-index: 2;
    .session-content-box {
      background-color: $background-color-1;
      max-width: 37rem;
      width: 100%;
      padding: 2rem;
      border: 1px solid #ffff;
      box-shadow: 0 4px 48px rgba(16, 24, 40, 0.7);
      border-radius: 20px;
      .session-title {
        justify-content: center;
      }
      .title {
        color: $background-color;
        background: $background-color;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        justify-content: center;

        p {
          font-size: 42px;
        }
      }
      .session-subtitle {
        text-align: center;
        justify-content: center;
        margin-top: 1rem;
      }
      .button-terms-and-condition {
        button {
          justify-content: center;
        }
      }
    }
    .login-inputs {
      margin-top: 3.5rem;
      justify-content: center;
      padding: 0 15%;

      .login-menu-password {
        margin-top: 1rem;
        justify-content: center;
      }
    }
    .login-menu-button {
      margin-top: 2.5rem;
      justify-content: center;
      button {
        background: $background-color-gradiant;
        transition: 1s;
        cursor: pointer;
      }
      button:hover {
        background-position: right center; /* change the direction of the change here */
        background-size: 200% 200%;
      }
    }

    hr {
      height: 2px;
      border-color: $secondary-color-2;
      margin: 3rem 0rem;
    }

    .create-account {
      align-items: center;
      justify-content: center;
    }
  
  }
}

/* Estilos para una resolución mínima de la resolución inicial de un escritorio y máxima de 1366x710 */
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (min-height: 600px) and (max-height: 832px) {
  .desktop-session {
    .session-content {
      .session-content-box {
        margin: 3rem 3rem;
        .title {
          p {
            font-size: 32px;
          }
        }
      }
      .login-inputs {
        margin-top: 2rem;
        padding: 0 15%;
      }
      .login-menu-button {
        margin-top: 1rem;
        justify-content: center;
        button {
          height: 2.5rem;
        }
      }
      hr {
        height: 2px;
        border-color: $secondary-color-2;
        margin: 1.5rem 0rem;
      }
    }
  }
}
@media only screen and (min-width: 1367px) and (max-width: 1518px) and (min-height: 600px) and (max-height: 832px) {
  .desktop-session {
    .session-content {
      .session-content-box {
        margin: 4rem 3rem;
        .title {
          p {
            font-size: 36px;
          }
        }
      }
      .login-inputs {
        .login-menu-password {
          margin-top: 1rem;
        }
      }
      .login-menu-button {
        margin-top: 2rem;
        justify-content: center;
        button {
          height: 2.5rem;
        }
      }
      hr {
        height: 2px;
        border-color: $secondary-color-2;
        margin: 2rem 0rem;
      }
    }
  }
}
