@import "styles/variables.scss";

.associate-product-with-supplier-drawer {
  .input-drawer {
    margin-bottom: $drawer-item-margin-bottom;

    p {
      margin-bottom: 0.5rem;
    }
  }

  .input-drawer.add-product-search {
    .typography {
      margin-bottom: 0.5rem;
    }
  }

  .products-to-add-list {
    .product-to-add {
    }
  }
}
