@import "styles/variables.scss";

div.password {
  .text-field {
    margin-top: 0.75rem;
    .helper-text {
      margin-top: 5px;
      margin-bottom: 5px;
      .success {
        color: green;
      }

      .warning {
        color: orange;
      }

      .error {
        color: red;
      }
    }
  }

  div.password-input-material {
    width: 100%;

    input {
      height: 1.5rem;
    }

    // Editing Material components
    .MuiInputBase-formControl {
      border-radius: 8px;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color-1 !important;
    }
    .css-hjtp1-MuiInputBase-root-MuiOutlinedInput-root:hover
      .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color-2 !important;
    }
  }

  div.with-helper-error {
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $red-color-2 !important;
    }
    .css-hjtp1-MuiInputBase-root-MuiOutlinedInput-root:hover
      .MuiOutlinedInput-notchedOutline {
      border-color: $red-color-2 !important;
    }

    fieldset {
      border-color: $red-color-2 !important;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1518px) and (min-height: 600px) and (max-height: 832px) {
  div.password {
    div.password-input-material {
      input {
        height: 1rem;
      }
    }
  }
}
