@import "styles/variables.scss";

div.collect-customer-drawer {
  .box-payment-methods {
    button {
      padding: 0 !important;
    }
    .payment-method {
      margin-top: 2rem;
      border-radius: 8px;
      border: 1px;
      border: 1px solid #afb9ce;
      box-shadow: 0px 1px 4px 0px #00000026;
      height: 5rem;
      width: 8rem;
      .payment-method-button {
        .payment-icon {
          justify-content: center;
        }

        .payment-title {
          justify-content: center;
        }
      }
    }
  }

  .total-to-collect {
    margin-top: 1.5rem;
  }

  .amount-accredited-input {
    margin-top: 1.5rem;
  }

  .amount-accredited {
    margin-top: 2rem;
  }

  .returned-amount {
    input {
      color: $red-color-2 !important;
    }
  }

  .checkbox-mora {
    margin-top: 2.5rem;
    margin-bottom: 1rem;

    align-items: center;
    p {
      padding-left: 0.25rem;
    }
  }

  .collect-rent-drawer-cash {
    .not-valid-amount-to-paid {
      margin-top: 2.5rem;
      margin-bottom: 1rem;

      align-items: center;
      p {
        padding-left: 0.25rem;
      }
    }

    .remaining-balance {
      margin-top: 2.5rem;
      margin-bottom: 1rem;

      input {
        color: $red-color-2 !important;
      }
    }
  }

  .customer-pay {
    margin-top: 2.5rem;

    .customer-pay-content {
      .input-drawer.create-customer-button {
        margin-bottom: 2rem;
      }
    }
  }
}
