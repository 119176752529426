@import "styles/variables.scss";

div.menu-top-content {
  position: fixed;
  display: flex;
  z-index: 1;
  background-color: $background-color-1;
  right: 0rem;
  height: $menu-top-flex-height;
  align-items: center;
  width: 92.8%;
  div.menu-title {
    padding-left: 4rem;
  }

  div.button-menu {
    display: flex;
    justify-content: flex-end;
    padding-right: 4rem;
  }
  .button-menu-open-drawer {
    margin-top: 1rem;
  }
}
