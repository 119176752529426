@import "styles/variables.scss";

.account-status {
  .account-status-dashboard-spinner-loading {
    margin-top: 50vh;
  }

  .account-status-content {
    padding: 0 3rem;
    padding-top: $menu-top-height;
  }

  .account-status-header-buttons {
    display: flex;
    justify-content: end;

    div {
      width: 13rem;
      margin: 0 1rem;
    }
  }
  .account-status-cards {
    margin-bottom: 2rem;
    div.account-status-card {
      transition: transform 0.3s ease; // Transición suave para el contenedor
  
      &:hover {
        transform: scale(1.05); // Aumenta el tamaño del contenedor principal al hacer hover
      }
      margin-right: 3rem;
      width: 20rem;
      height: 6.5rem;
      border: 1px solid $secondary-color-1;
      text-align: center;
      box-shadow: 0px 1px 4px 0px $secondary-color-1;
      border-radius: 10px;
      padding: 20px;

      svg {
        margin-right: 1rem;
        margin-bottom: 1rem;
        background-color: $secondary-color-1;
        border-radius: 50px;
        padding: 2px;
      }
    }

    & .balance {
      justify-self: start;
      .balance-amount.type-neutral p {
        color: $secondary-color-2;
      }
      .balance-amount.type-income p {
        color: $green-color-2;
      }
      .balance-amount.type-outcome p {
        color: $red-color-2;
      }
    }
    & .income {
      justify-self: center;

      .income-amount.type-neutral p {
        color: $secondary-color-2;
      }
      .income-amount.type-income p {
        color: $green-color-2;
      }
      .income-amount.type-outcome p {
        color: $red-color-2;
      }
    }
    & .outcome {
      justify-self: end;

      .outcome-amount.type-neutral p {
        color: $secondary-color-2;
      }
      .outcome-amount.type-income p {
        color: $green-color-2;
      }
      .outcome-amount.type-outcome p {
        color: $red-color-2;
      }
    }
  }

  .account-status-table-content {
    &-income p {
      color: $green-color-2;
    }

    &-outcome p {
      color: $red-color-2;
    }

    &-balance p {
      color: $green-color-2;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1518px) and (min-height: 600px) and (max-height: 832px) {
  .account-status {
    .account-status-content {
      padding: 0 2rem;
      margin-top: calc($menu-top-height - 1rem);
    }
  }
}
