@import "styles/variables.scss";

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: local("Lato-Regular"),
    url("../../../../fonts/Lato/Lato-Regular.ttf") format("truetype");
}

div.search {
  div.text-field-search-desktop {
    div {
      border-radius: 12px;
      background-color: $background-color-4;
    }

    input {
      font-family: "Lato", sans-serif;
      color: $dark-color-3;
      font-size: 14px;
      line-height: 24px;
    }
  }

  fieldset {
    border-color: transparent !important;
  }
  .icon-button-filter {
    svg {
      padding-top: 6px;
    }
  }
}

.MuiAutocomplete-popper {
  margin-top: 0.25rem !important;

  div {
    border-radius: 10px;
  }
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding-right: 40px !important;
}

div.autocomplete-search {
  width: 100%;
  div {
    border-radius: 8px;
    // background-color: $background-color-4;
  }
  input {
    font-family: "Lato", sans-serif;
    color: $dark-color-3;
    font-size: 14px;
    line-height: 24px;
    height: 1.5rem;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $primary-color-1 !important;
  }
}
