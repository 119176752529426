@import "styles/variables.scss";

div.number-input {
  max-height: 6rem;

  .number-input-line {
    input {
      font-size: 32px;
      margin: 8px 0;
      border: none;
    }
    input:focus {
      outline: none;
    }
    
    border-bottom: 1px solid $primary-color-1;
  }

  input {
    height: 1.5rem;
  }
  .helper-text {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .helper-text.success p {
    color: green;
  }

  .helper-text.warning p {
    color: orange;
  }

  .helper-text.error p {
    color: red;
  }
  // Editing Material components
  .MuiInputBase-formControl {
    margin-top: 0.75rem;
    border-radius: 8px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $primary-color-1 !important;
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: $primary-color-2 !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .number-input-label.input-required p::after {
    content: ' *';
    color: red;
  }
}
