@import "styles/variables.scss";

div.products {
  margin-top: 1.5rem;
  .products__view {
    padding-left: 3rem;
    box-shadow: none;

    .MuiButton-outlined {
      border-color: $primary-color-1;
      color: $primary-color-1;

      &:hover {
        background-color: $primary-color-1;
        color: white;
      }
    }

    .MuiButton-contained {
      background-color: $primary-color-1;
      color: white;

      &:hover {
        background-color: darken($primary-color-1, 10%);
      }
    }

    button {
      border-color: $primary-color-1;
    }
  }
}
