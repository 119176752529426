div.close-checkout-modal {
  .confirmation-modal-title {
    margin-bottom: 1rem;
  }
  .confirmation-modal-description {
    margin-bottom: 1rem;
  }

  .close-checkout-amount {
    margin-bottom: 2rem;
  }

  .view-closed-checkout {
    &-title {
      margin-bottom: 1rem;
    }

    &-amount {
      margin-bottom: 2rem;

      .number-input {
        margin-bottom: 1rem;
      }
    }
  }
}