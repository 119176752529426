@import "styles/variables.scss";

.button-level-fill-width {
  button, a {
    width: 100%;
  }
}
.button-level-max-width {
  max-width: 14rem;

  button, a {
    width: 100%;
  }
}

.button-level {
  display: flex;
  width: 100%;

  a:hover {
    background-color: initial;
  }
  button:hover {
    background-color: initial;
  }

  button, a {
    border-radius: 50px;
    display: flex;
    text-transform: none;
  }
  button:focus {
    outline: none;
    color: transparent;
  }
  a:focus {
    outline: none;
    color: transparent;
  }
}

// Alignment
.button-level.button-align-center, .a-level.button-align-center {
  justify-content: center;
  button,
  a {
    justify-content: center;
  }
}

.button-level.button-align-right, .a-level.button-align-right {
  justify-content: right;
  button,
  a {
    justify-content: right;
  }
}

.button-level.button-align-left, .a-level.button-align-left {
  justify-content: left;
  button,
  a {
    justify-content: left;
  }
}

// Variant ------------

// Contained button
.button-variant-contained {
  button,
  a {
    height: 3rem;
    min-width: 14rem;
  }
}

// Outlined button
.button-variant-outlined {
  button,
  a {
    height: 3rem;
    min-width: 14rem;
  }
}

// -------------

// Color ------------

// Contained button
.button-variant-contained.button-level.button-level-color-primary {
  button,
  a {
    background-color: $primary-color-1;
    p {
      color: $background-color-2;
    }
  }

  &:hover {
    button,
    a {
      background-color: rgba($primary-color-1, 0.9);
    }
  }
}
.button-variant-contained.button-level.button-level-color-secondary {
  button,
  a {
    background-color: $primary-color-2;
    p {
      color: $background-color-2;
    }
  }
}
.button-variant-contained.button-level.button-level-color-disabled {
  button,
  a {
    background-color: $secondary-color-2 !important;
    p {
      color: $background-color-2;
    }
  }
}
.button-variant-contained.button-level.button-level-color-error {
  button,
  a {
    background-color: $red-color-2 !important;
    p {
      color: $background-color-2;
    }
  }
}
.button-variant-contained.button-level.button-level-color-white {
  button,
  a {
    background-color: $background-color-1;
    p {
      color: $primary-color-2;
    }
  }
}

// Outlined button
.button-variant-outlined.button-level.button-level-color-primary {
  button,
  a {
    border-color: $primary-color-1;
    p {
      color: $primary-color-1;
    }
  }
}
.button-variant-outlined.button-level.button-level-color-secondary {
  button,
  a {
    border-color: $background-color-3;
    p {
      color: $primary-color-2;
    }
  }
}
.button-variant-outlined.button-level.button-level-color-white {
  button,
  a {
    border-color: $secondary-color-2;
    p {
      color: $background-color-1;
    }
  }
}
.button-variant-outlined.button-level.button-level-color-disabled {
  button,
  a {
    border-color: $secondary-color-2;
    p {
      color: $secondary-color-2;
    }
  }
}
.button-variant-outlined.button-level.button-level-color-error {
  button,
  a {
    border-color: $red-color-2;
    p {
      color: $red-color-2;
    }
  }
}

// Text button
.button-variant-text.button-level.button-level-color-primary {
  button,
  a {
    border-color: $primary-color-1;
    p {
      color: $primary-color-1;
    }
  }
}
.button-variant-text.button-level.button-level-color-secondary {
  button,
  a {
    border-color: $background-color-3;
    p {
      color: $primary-color-2;
    }
  }
}
.button-variant-text.button-level.button-level-color-white {
  button,
  a {
    border-color: $background-color-3;
    p {
      color: $background-color-1;
    }
  }
}
.button-variant-text.button-level.button-level-color-disabled {
  button,
  a {
    border-color: $secondary-color-2;
    p {
      color: $secondary-color-2;
    }
  }
}
.button-variant-text.button-level.button-level-color-error {
  button,
  a {
    border-color: $red-color-2;
    p {
      color: $red-color-2;
    }
  }
}

// -------------

// Disabled ------------

.button-variant-contained.button-level.disabled {
  button,
  a {
    background-color: $secondary-color-2 !important;
    p {
      color: $background-color-2;
    }
  }
}
.button-variant-text.button-level.disabled {
  p {
    color: $secondary-color-2 !important;
  }
  path {
    fill: $secondary-color-2 !important;
  }
}
.button-variant-outlined.button-level.disabled {
  p {
    color: $secondary-color-2 !important;
  }
  path {
    fill: $secondary-color-2 !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1518px) and (min-height: 600px) and (max-height: 832px) {
  .button-variant-contained {
    button,
    a {
      height: 2.8rem;
    }
  }
  
  // Outlined button
  .button-variant-outlined {
    button,
    a {
      height: 2.7rem;
    }
  }
 }