@import "styles/variables.scss";

.desktop-movement {
  .movement-dashboard-spinner-loading {
    margin-top: 50vh;
  }
  .movement-dashboard-header-buttons {
    div {
      width: 13rem;
      margin: 0 1rem;
    }
  }

  div.movement-dashboard-content {
    padding: 0 3rem;
    margin-top: $menu-top-height;
  }

  .table {
    .movement-type-color-positive p {
      color: $green-color-2;
    }
    .movement-type-color-negative p {
      color: $red-color-2;
    }
  }
}
