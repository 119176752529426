@import "styles/variables.scss";

div.desktop-checkout {
  div.desktop-checkout-content {
    padding: 0 3rem;
    margin-top: $menu-top-height;

    .current-checkout p {
      margin: 1rem 0;
    }

    div.carousel {
      margin-bottom: 2rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
    }

    div.card-content {
      &-title {
        margin-bottom: 1rem;
        &-icon {
          margin-right: 0.75rem;

          svg {
            background-color: $secondary-color-1;
            border-radius: 50px;
            padding: 2px;
          }
        }
      }
    }
  }
}
