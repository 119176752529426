@import "styles/variables.scss";

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato-Regular'), url('../../../../fonts/Lato/Lato-Regular.ttf') format('truetype');
}

div.typography {
  // General
  p {
    font-family: "Lato", sans-serif;
    color: $dark-color-3;
  }

  // Variants
  p.typography-variant-h1 {
    font-size: 42px;
    line-height: 50px;
  }
  p.typography-variant-h2 {
    font-size: 32px;
    line-height: 38px;
  }
  p.typography-variant-paragraph-24 {
    font-size: 24px;
    line-height: 28.8px;
  }
  p.typography-variant-paragraph-22 {
    font-size: 22px;
    line-height: 24px;
  }
  p.typography-variant-paragraph-20 {
    font-size: 20px;
    line-height: 24px;
  }
  p.typography-variant-paragraph-18 {
    font-size: 18px;
    line-height: 24px;
  }
  p.typography-variant-paragraph-16 {
    font-size: 16px;
    line-height: 24px;
  }
  p.typography-variant-paragraph-14 {
    font-size: 14px;
    line-height: 24px;
  }
  p.typography-variant-paragraph-12 {
    font-size: 12px;
    line-height: 16px;
  }
  p.typography-variant-paragraph-10 {
    font-size: 10px;
    line-height: 12px;
  }
  p.typography-variant-table-content {
    font-size: 14px;
    line-height: 14px;
  }
  p.typography-variant-table-head {
    font-size: 14px;
    line-height: 13px;
  }
  p.typography-variant-button1 {
    font-size: 14px;
    line-height: 17px;
  }
  p.typography-variant-button2 {
    font-size: 12px;
    line-height: 14px;
  }
  p.typography-variant-input1 {
    font-size: 14px;
    line-height: 17px;
  }
  p.typography-variant-input2 {
    font-size: 12px;
    line-height: 14px;
  }
  
  // Types
  p.typography-type-regular {
    font-weight: 400;
  }
  p.typography-type-medium {
    font-weight: 500;
  }
  p.typography-type-semibold {
    font-weight: 600;
  }
  p.typography-type-bold {
    font-weight: 700;
  }
}
