@import "styles/variables.scss";

.update-product-drawer {
  .update-product-drawer-item {
    margin-bottom: $drawer-item-margin-bottom;
  }

  .edit-product-drawer-item {
    margin: 1rem 0;
  }
}
