@import "styles/variables.scss";

div.carousel {
  div.slick-slider {
    max-width: 85vw;
    margin: 0 auto;
    padding: 1rem;
  }

  div.carousel-card {
    min-height: 100px;
    max-width: 85%;
    border: 1px solid $secondary-color-1;
    border-radius: 16px;
    padding: 1rem;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  }
}
.slick-prev:before,
.slick-next:before {
  line-height: 1;
  opacity: 0.75;
  color: rgb(124, 18, 18);
  font-size: 0;
}
