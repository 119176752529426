@import "styles/variables.scss";

div.checkout-detail-drawer {
  .checkout-detail-drawer-shift {

    margin: 1rem 0;
    padding-bottom: 1rem;
    border-bottom: 1px solid $secondary-color-2;
  }

  .checkout-detail-drawer-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

   
  }
}