@import "styles/variables.scss";

div.multiline-input {
  div.text-field-multiline {
    width: 100%;
    .helper-text {
      margin-top: 5px;
      margin-bottom: 5px;
      .success {
        color: green;
      }

      .warning {
        color: orange;
      }

      .error {
        color: red;
      }
    }
  }
  .text-input-multiline {
    margin-top: 0.75rem;
    width: 100%;

    textarea {
      padding-right: 1rem;
    }
  }

  // Editing Material components
  .MuiInputBase-formControl {
    border-radius: 8px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $primary-color-1 !important;
  }
  .css-l62xy5-MuiInputBase-root-MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: $primary-color-1 !important;
  }
}
