@import "styles/variables.scss";

div.create-promotion-drawer {
  .input-drawer {
    margin-bottom: $drawer-item-margin-bottom;

    .products {
      // margin-bottom: $drawer-item-margin-bottom;

      .product {
        margin-bottom: 0.75rem;
      }
    }
  }

  .input-drawer.add-product-search {
    .typography {
      margin-bottom: 0.5rem;
    }
  }
}
