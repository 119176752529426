// General variables

$desktop-body-width: 92.3%;
$desktop-menu-width: 7.7%;
$menu-top-height: 1.5rem;
$menu-top-height-emptyState: 6%;
$menu-top-flex-height: 13%;

//magin body into pages and emptystate

// Drawer variables
$drawer-width: 18%;
$drawer-item-margin-bottom: 1.5rem;

// Scroll variables
$scrollbar-width: 6px;
$scrollbar-color: #7987a4;
$scrollbar-background-color: transparent;
$scrollbar-radius: 10px;

// Color variables
$primary-color-1: #e88e72;
$primary-color-2: #8b3bd5;
$background-color-gradiant: linear-gradient(90deg, #e88e72, #8b3bd5);
$background-color: -webkit-linear-gradient(
  249.79deg,
  #e88e72 -51.17%,
  #8b3bd5 103.66%
);

$secondary-color-1: #e5e8f7;
$secondary-color-2: #afb9ce;
$secondary-color-3: #7987a4;

$tertiary-color-1: #e4cffa;
$tertiary-color-2: #8b3bd5;
$tertiary-color-3: #6e19ba;

$dark-color-1: #2d3749;
$dark-color-2: #151f2f;
$dark-color-3: #10151f;

$yellow-color-1: #ffee57;
$yellow-color-2: #ffe066;
$yellow-color-3: #e09900;

$red-color-1: #ff5975;
$red-color-2: #f51b5c;
$red-color-3: #ff8398;

$green-color-1: #bde7da;
$green-color-2: #51c6a1;
$green-color-3: #036d4c;

$background-color-1: #ffffff;
$background-color-2: #f5f6fc;
$background-color-3: #d7dbf1;
$background-color-4: #e4e9ff;

$primary-light-color: #e88e7299;

// Scrollbar
// to use you have to add class .scrollbar-custom to the element
/*
.element-con-scrollbar {
  // Utiliza la clase scrollbar-custom para aplicar los estilos del scrollbar
  @extend .scrollbar-custom;
  // Otros estilos específicos para el elemento
}
*/
.scrollbar-custom {
  overflow: auto;
  ::-webkit-scrollbar {
    width: $scrollbar-width;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    border-radius: $scrollbar-radius;
  }
  ::-webkit-scrollbar-track {
    background-color: $scrollbar-background-color;
    margin: 20px 0px;
  }

  &::-webkit-scrollbar {
    width: $scrollbar-width;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-color;
    border-radius: $scrollbar-radius;
  }
  &::-webkit-scrollbar-track {
    background-color: $scrollbar-background-color;
    margin-bottom: 25px;
    margin-top: 5px;
  }
}
