@import "styles/variables.scss";

.tag-drawer-options {
  .select-action {
    margin-bottom: 2rem;
  }
  .create-tag-drawer {
    .create-tag-drawer-item {
      margin-bottom: $drawer-item-margin-bottom;
    }
  }

  .edit-tag-drawer {
    .edit-tag-drawer-item {
      margin-bottom: $drawer-item-margin-bottom;
    }
  }
}
