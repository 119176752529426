@import "styles/variables.scss";

.side-drawer-container {
  height: 100%;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
  @extend .scrollbar-custom;
.side-drawer-header {
  padding: 0 !important;
  .body-title {
    padding-left: 2rem;
  }
}
  .side-drawer-header {
    padding: 32px 0px 32px 55px;
    height: 6rem;
    align-items: center;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px;
    border-color: $secondary-color-2;
    border-style: solid;
  }

  .side-drawer-body {
    padding: 1rem 2rem;
    min-height: calc(
      100% - 12rem
    ); //12 rem = 6rem headers + 6rem button drawer;

    display: flex;
    flex-direction: column;

    .attention-box {
      border-radius: 10px;
      background-color: $background-color-2;
      padding: 1rem;
      border: 1px solid $secondary-color-1;
  
      .attention-box-title {
        margin-bottom: 0.25rem;
        width: 100%;
      }
  
      margin-bottom: 1rem;
    }
  }

  .buttons-drawer {
    max-height: 6rem;
    height: 6rem;
    border-top: solid 1px $secondary-color-2;

    text-align: center;
    align-items: center;
    display: flex;
    justify-content: space-between;

    div.button-level {
      width: 80%;
    }
    div.button-drawer {
      display: flex;
      justify-content: center;
    }
  }
}

.drawer-exit {
  .drawer-body-exit {
    padding-top: 15%;
    .drawer-exit-title {
      justify-content: center;
    }
    .drawer-exit-img {
      padding-top: 4rem;
      justify-content: center;
    }
    .drawer-exit-text {
      padding-top: 4rem;

      justify-content: center;
      text-align: center;
    }
  }
}
